<template>

	<o-modal v-model:active="alertLogin" :canCancel=false :width="400" style="z-index:10000;">
		<div style="padding:20px; background-color:white; text-align:center;  border-radius:10px">
			<o-input v-model="login" placeholder="Логин" style="margin-bottom:10px;" @keyup.enter="logon" autocomplete="on" />
			<o-input v-model="password" type="password" placeholder="Пароль" password-reveal @keyup.enter="logon" autocomplete="on" />
			<p>{{ alertMessage }}</p>
			<o-button label="Войти" variant="primary" @click="logon" style="margin:20px auto;"/>
		</div>
	</o-modal>

	<o-loading v-model:active="isLoading" :fullPage=false :canCancel=true>
		<o-icon pack="fas" icon="sync-alt" size="large" spin />
	</o-loading>
	<sidebar-menu :menu="menu" :theme="'white-theme'" v-model:collapsed="collapsed" @update:collapsed="onToggleCollapse" ref="menu" />
	<div v-if="isOnMobile && !collapsed" class="sidebar-overlay" @click="collapsed=true"/>
	<div v-if="user_id" id="container" :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]">
		<Header />
		<router-view/>
	</div>
</template>

<script>
	import Header from './components/Header.vue'
	export default {
		name: 'App',
		components: {
			Header
		},
		//watch:{$route(to, from) {}},
		data() {
			return {
				api: 'https://uniaffcrm.com/api.php',
				user: {},
				admin: 1,
				user_id: 0,
				alertLogin: true,
				alertMessage: '',
				login: '',
				password: '',
				menu: [
					{ href:'/', title:'Дашбоард', icon:'fa fa-dashboard', resend:true, Alexandra:true },
					{ href:'/tracking', title:'Tracking', icon:'fa fa-chart-bar', disabled:true, tracking:true },
					{ href:'/task_tracking', title:'Task Tracking', icon:'fa fa-list-check', },
					{ href:'/creos', title:'Креативы', icon:'fa fa-image' },
					{ href:'/offers', title:'Офферы', icon:'fa fa-desktop' },
					{ href:'/finances', title:'Финансы', icon:'fa fa-hand-holding-dollar', disabled:true },
					{ href:'/affp', title:'ПП', icon:'fa fa-money-bill-transfer', disabled:true },
					{ href:'/users', title:'Пользователи', icon:'fa fa-user', disabled:true, tl:true },
					{ href:'/analytics', title:'Время просмотра', icon:'fa fa-clock', disabled:false },
					{ href:'/leads', title:'Выгрузка лидов', icon:'fa fa-phone', disabled:true, resend:true },
					{ title:'Переотправка', icon:'fa fa-repeat', disabled:true, resend:true, child: [
						{ href:'/resend', title:'Настройки переотправки', icon:'fa fa-paper-plane', resend:true, },
						{ href:'/leadsstat', title:'Статистика лидов', icon:'fa fa-chart-column', resend:true },
						{ href:'/sendoffers', title:'Офферы цель', icon:'fa fa-capsules', resend:true },
						{ href:'/sendaffs', title:'ПП', icon:'fa fa-handshake', resend:true },
						{ href:'/sendbranches', title:'Филиалы', icon:'fa fa-at', resend:true },
					]},
					//{ href:'/prize', title:'Призы', icon:'fa fa-award', disabled:false, style:'color:red' },
					//{ href:'/tools', title:'Инструменты', icon:'fa fa-tools', disabled:true },
				],
				collapsed: false,
				isOnMobile: false,
				isLoading: false,
				verticals: {'1':'Диабет', '2':'Геморрой', '3':'Простатит', '4':'Потенция', '5':'Паразиты', '6':'Грибок', '7':'Похудение', '8':'Омоложение', '9':'Кардио', '10':'Слух', '11':'Суставы', '12':'Зрение', '13':'Варикоз', '14':'Печень', '15':'Гипертония', '16':'Цистит', },
				statuses: {'0':'Все статусы', '1':'Ожидание', '2':'Продажа', '3':'Отмена', '4':'Трэш', },
				branches: ['', 'unionaff', 'south', 'tuna', 'zhb', 'octo', 'shark', 'kit', 'ss', 'neva', 'buran', 'basis'],
			}
		},
		methods: {
			loadData(params, ref, callback) {
				this.isLoading = true
				let api = (params?.api ? params.api : this.$root.api)
				let formData = new FormData()
				if(ref) {
					formData.append('action', ref.$.type.name)
				}
				for(var key in params) formData.append(key, params[key])
				this.axios.post(api, formData, {headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
					if(!response.data.error) {
						//if(ref) ref.data = response.data
						if(callback) callback(response.data)
					} else {
						this.alertLogin = true
						this.alertMessage = response.data.msg
					}
					this.isLoading = false
				}).catch((error) => { console.log(error) });
			},
			onToggleCollapse(collapsed) {
			},
			onResize() {
				if(window.innerWidth <= 767) {
					this.isOnMobile = true
					this.collapsed = true
				} else {
					this.isOnMobile = false
					this.collapsed = false
				}
			},
			units(column) {
				return(column.match(/^(cr|approve|roi_confirmed|percent)$/) ? '%' : (column.match(/^(cpa|cost|profit_confirmed|balance|amount|sale_revenue|bro)$/) ? ' $' : ''))
			},
			formatDate(d) {
				if(d) {
					let year = d.getFullYear().toString()
					let month = (d.getMonth()+1).toString()
					let day = d.getDate().toString()
					let hour = d.getHours().toString()
					let min = d.getMinutes().toString()
					return(`${year}-${month}-${day} ${hour}:${min}:00`)
				} else return ''
			},
			logon() {
				let browser_id = Math.floor(Math.random() * Date.now())
				localStorage.setItem('browser_id', browser_id);
				this.axios.post(this.$root.api, {action:'Login', browser_id:browser_id, login:this.login, password:this.password}, {headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
					this.logonCheck(response.data)
				})
			},
			logonCheck(data) {
				if(data.user) {
					this.user = data
					this.user.name = data.user
					this.admin = (data.admin == '1')
					this.user_id = data.user_id
					this.$refs.menu.menu.forEach((e) => {
						if(this.user.name == 'Alexandra') { e.disabled = !e.tracking } else { e.disabled = (e.disabled && !this.admin) }
						if(this.user.name == 'ZHB_Nikita' && e.tracking) { e.disabled = false }
						if((this.user.role_id == 1 || this.user.role_id == 2) && e.tl) { e.disabled = false }
						//if(this.user.name == 'kciforp' || this.user.name == 'Koxxx') { e.disabled = !e.resend } else { e.disabled = (e.disabled && !this.admin) }
						// if(e.child) { e.child.forEach((e) => { if(resend) { e.disabled = !e.resend } else { e.disabled = (e.disabled && !this.admin) } })}
					})
					this.alertLogin = false
				} else {
					this.alertMessage = data.msg
				}
			},
		},


		mounted() {
			let browser_id = localStorage.getItem('browser_id')
			if(browser_id) {
				this.axios.post(this.$root.api, {action:'Login', browser_id:browser_id}, {headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
					this.logonCheck(response.data)
				})
			} else {
			}
			this.onResize()
			window.addEventListener('resize', this.onResize)
	  },

	}
</script>

<style>
@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
@import './assets/css/material-icons.min.css';

@media (max-width:1350px) and (min-width:450px){
	table { font-size:90%; }
}
#container { padding:20px 20px 20px 210px; transition: 0.3s ease; }
#container.collapsed, #container.onmobile { padding:5px 5px 5px 65px!important; }
.sidebar-overlay{ position:fixed; width: 100%; height:100%; top:0; left:0; background-color:#000; opacity:0.5; z-index:10; }
.v-sidebar-menu{ z-index:11!important; }
.v-sidebar-menu.vsm_expanded{ max-width:210px!important; }
.vsm--link_open, .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--link_level-1.vsm--link_open .vsm--icon { background-color:#00d1b2!important; }
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active { -webkit-box-shadow: inset 3px 0 0 0 #00d1b2!important; box-shadow: inset 3px 0 0 0 #00d1b2!important; }
.vsm--link_disabled{ display:none!important; }

.table-wrapper{ width:100%; }
td{ white-space:nowrap; }
th{ text-align:center!important; font-size:0.9em; }
.field{ display:flex; margin-bottom:0; }
.tools{ display:flex; margin-bottom:6px; }
.filters{ display:flex; margin-left:auto; margin-right:0; width:auto; }
.filters .control{ margin-left:1em; }
.button{ margin-right:1em; }
label{ margin-bottom:6px!important; }

.b-table{ width:100%; margin:0; }
.b-table td span, .b-table td div{ cursor:pointer; }
@media (min-device-width:769px) {
	.b-table td{ height:1px; }
}
.notactive{ color:#ccc; }
.notactive select{ color:#ccc; border-color:#ccc!important; }
.empty{ display:block; width:100%; height:100%; }

</style>
