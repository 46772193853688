<template>

	<div class="users">
		<o-field variant="primary">
			<o-button @click="this.add()" variant="primary" outlined size="small">
				<span v-if="!this.addForm">Добавить</span>
				<span v-if="this.addForm">Сохранить</span>
			</o-button>
			<template v-if="this.addForm">
				<o-button @click="addForm=false, this.data.shift()" variant="warning" outlined size="small">Отмена</o-button>
			</template>
		</o-field>

		<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">

				<template v-if="column.field=='active'">
					<o-icon pack="fas" :icon="row.active==1?'check':'xmark'" size="small" :variant="row.active==1?'primary':'warning'" @click="active(row.id)" />
				</template>

				<template v-else-if="row.active==0">
					<span class='notactive'>{{ row[column.field] }}</span>
				</template>

				<template v-else>

					<template v-if="column.field=='branch_id'">
						<o-field variant="primary">
							<o-select size="small" v-model="row[column.field]" @change="send(row.id, column.field, row[column.field])">
								<option v-for="(item, key) in this.$root.branches" :value="key">
									{{ item }}
								</option>
							</o-select>
						</o-field>
					</template>

					<template v-else-if="column.field=='role_id'">
						<o-field :variant="row.role_id==3?'primary':'danger'">
							<o-select size="small" v-model="row[column.field]" @change="send(row.id, column.field, row[column.field])">
								<option v-for="(item, key) in this.roles" :value="key">
									{{ item }}
								</option>
							</o-select>
						</o-field>
					</template>

					<template v-else-if="column.field=='team_id'">
						<o-field variant="primary">
							<o-select size="small" v-model="row[column.field]" @change="send(row.id, column.field, row[column.field])">
								<option v-for="(item, key) in this.teams" :value="key">
									{{ item }}
								</option>
							</o-select>
						</o-field>
					</template>


					<template v-else-if="this.edit==column.field+row.id || (this.addForm && row.id==-1)">
						<o-input v-model="row[column.field]" :ref="column.field" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)" @keyup.enter="send(row.id, column.field, row[column.field])" size="small" variant="primary" />
					</template>

					<template v-else-if="!row[column.field]">
						<div @click="this.focus(row, column.field)" class="empty"></div>
					</template>
					<template v-else>
						<span @click="this.focus(row, column.field)">{{ row[column.field] }}</span>
					</template>

				</template>

			</o-table-column>
		</o-table>

	</div>
</template>

<script>
	export default {
		name: 'Users',
		data() {
			return {
				columns: [
					{ field:'name', label:'Логин' },
					{ field:'sub_id_2', label:'sub_id_2' },
					{ field:'fio', label:'ФИО' },
					{ field:'tg', label:'tg' },
					{ field:'month_goal', label:'Цель мес. $' },
					{ field:'role_id', label:'Роль' },
					{ field:'team_id', label:'Команда' },
					{ field:'branch_id', label:'KT' },
					{ field:'kt_api_key', label:'KT API key' },
					{ field:'password', label:'пароль' },
					{ field:'active', label:'вкл/откл', position:'centered' },
				],
				data: [],
				branches: {
					'':'unionaff',
					'south':'south',
				},
				edit: '',
				prev: '',
				addForm: false,
				roles: { '0':'', '1':'Админ', '2':'Тимлид', '3':'Байер', },
				teams: {},
			}
		},
		methods: {
			active(id) {
				this.$root.loadData({'id':id}, this, (dt) => {
					this.data = dt
				})
			},
			send(id, field, value) {
				if(!this.addForm)
					this.$root.loadData({'id':id, 'field':field, 'value':value}, this, (dt) => {
						this.data = dt
						this.edit = ''
						this.teams = {}; dt.forEach((row) => { if(row.team) this.teams[row.id] = row.team })
					})
			},
			add() {
				this.edit = '';
				if(!this.addForm) {
					const newUser = {id:-1, active:1}
					this.data.unshift(newUser)
				} else {
					this.$root.loadData(this.data[0], this, (dt) => {
						this.data = dt
						this.teams = {}; dt.forEach((row) => { if(row.team) this.teams[row.id] = row.team })
					})
				}
				this.addForm = !this.addForm
			},
			focus(row, field) {
				this.prev = row[field]
				this.edit = field+row.id
				if(field == 'password') {
					row.password = ''
				}
				if(this.addForm) {
					this.data.shift()
					this.addForm = false
				}
			},
			blur(row, field) {
				if(!this.addForm) {
					row[field]=this.prev
					this.edit=''
				}
			},
		},
		mounted() {
			this.$root.loadData({'user':this.$root.user.name, 'team_id':this.$root.user.team_id, 'admin':this.$root.admin?1:0 }, this, (dt) => {
				this.data = dt
				this.teams = {}; dt.forEach((row) => { if(row.team) this.teams[row.id] = row.team })
			})
		},
	}

</script>

<style>

</style>
