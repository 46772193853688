<template>
	<div class="tools">
		<div class="filters">
			<Interval ref="interval" @change="this.interval=$event, this.loadData($event)" />
		</div>
	</div>

 	<o-table ref="table" :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true :detailed=true :mobile-cards=false :show-detail-icon=false>
		<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column">

			<template #default="props">
				<div @click="toggle(props, this.interval)">
					<span :class="props.row.team==1?'team':''">{{ props.row[column.field] + (props.row[column.field] ? this.$root.units(column.field) : '') }}</span>
				</div>
			</template>

		</o-table-column>

		<template #detail="props">

		<o-table ref="table" :data="data_tasks[props.index]" :bordered=true :striped=true :narrowed=true :hoverable=true class="tasks" :mobile-cards=false>
			<o-table-column v-for="(column, idx) in columns_tasks" :key="idx" v-bind="column" v-slot="{ row }">
				<template v-if="column.field=='active'">
					<o-icon pack="fas" :icon="row.active==0?'check':'clock'" size="small" :variant="row.active==0?'primary':(row.enable_close==0?'warning':'primary')" @click="active(row, props.index, this.interval)" />
				</template>

				<template v-else-if="this.edit==column.field+row.id">
					<o-input v-model="row[column.field]" :ref="column.field" @blur="this.blur(row, column.field)" @keyup.esc="this.blur(row, column.field)" @keyup.enter="send(row.id, column.field, row[column.field], row.user_id, props.index)" size="small" variant="primary" />
				</template>

				<template v-else-if="!row[column.field]">
					<div @click="this.focus(row, column.field)" class="empty"></div>
				</template>
				<template v-else>
					<span @click="this.focus(row, column.field)">{{ row[column.field] }}</span>
				</template>

			</o-table-column>
		</o-table>

		</template>


	</o-table>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'TaskTracking',
		components: {
			Interval
		},
		data() {
			return {
				columns: [
				//	{ field:'id', label:'id', },
					{ field:'name', label:'Имя', },
					{ field:'cnt', label:'Количество задач', position:'centered' },
					{ field:'percent', label:'% выполнения', position:'centered' },
					{ field:'duration', label:'Продолжительность, ч.', position:'centered' },
				],
				data: [],
				columns_tasks: [
					{ field:'date', label:'Дата', },
					//{ field:'enable_close', label:'enable_close', },
					{ field:'active', label:'Выполнена', position:'centered' },
					{ field:'task', label:'Задача' },
					{ field:'duration', label:'Продолжительность, ч.', position:'centered' },
				],
				data_tasks: [],
				params: {},
				interval: {},
				edit: '',
			}
		},
		methods: {
			loadData(e) {
				if(e && e.interval) this.params.interval = e.interval
				if(e && e.interval == 'custom_date_range') {
					this.params.from = e.from
					this.params.to = e.to
				}
				this.params.id = ''
				this.params.user_id = ''
				this.$root.loadData(this.params, this, (dt) => {
					this.data = dt
				})
			},
			toggle(props, e) {
				if(!props.row.id || props.row.cnt==0) return
				if(e && e.interval) this.params.interval = e.interval
				if(e && e.interval == 'custom_date_range') {
					this.params.from = e.from
					this.params.to = e.to
				}
				this.params.user_id = props.row.id
				this.$root.loadData(this.params, this, (dt) => {
					this.data_tasks[props.index] = dt
				})
				props.toggleDetails(props.row)
			},
			active(row, index, e) {
				if(e && e.interval) this.params.interval = e.interval
				if(e && e.interval == 'custom_date_range') {
					this.params.from = e.from
					this.params.to = e.to
				}
				this.params.user_id = row.user_id
				this.params.id = row.id
				if(row.enable_close == 1) {
					this.$root.loadData(this.params, this, (dt) => {
						this.data_tasks[index] = dt
					})
				}
			},
			send(id, field, value, user_id, index) {
				this.$root.loadData({'id':id, 'field':field, 'value':value, 'user_id':user_id}, this, (dt) => {
					this.data_tasks[index] = dt
					this.edit = ''
					this.teams = {}; dt.forEach((row) => { if(row.team) this.teams[row.id] = row.team })
				})
			},
			focus(row, field) {
				this.prev = row[field]
				this.edit = field+row.id
			},
			blur(row, field) {
				row[field]=this.prev
				this.edit=''
			},

		},
		mounted() {
			this.loadData(null)
		},
	}
</script>

<style>
.team{ font-weight:bold; }
td[data-label="Дата"]{ width:8em!important; }
td[data-label="Выполнена"]{ width:5em!important; }
td[data-label="Продолжительность, ч."]{ width:5em!important; }
</style>
